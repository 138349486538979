html {
	scroll-behavior: smooth;
	font-family: "Montserrat", sans-serif;
	/* font-family: "Noto Serif JP", serif; */
	background-color: #e1e1e1;
}

body .btn {
	font-size: 17px;
}

body .btn-large {
	font-size: 20px;
	font-weight: 500 !important;
	line-height: 50px;
	height: 50px;
	padding: 0 20px;
	text-transform: uppercase;
}

#square .sq-content {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

#square {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	/* background-color: #2980b9; */
}

body .btn,
body .btn-large,
body .btn-small {
	font-family: "Montserrat", sans-serif;
	text-decoration: none;
	color: #fff;
	background-color: #9e0b0f;
	text-align: center;
	letter-spacing: 0.5px;
	transition: background-color 0.2s ease-out;
	cursor: pointer;
	box-shadow: none;
	border-radius: 2px;
	font-weight: 300;
	text-transform: uppercase;
}

body .btn:hover,
body .btn-large:hover,
body .btn-small:hover {
	background-color: #000 !important;
	-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
		0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -1px rgba(0, 0, 0, 0.2);
	color: white;
}

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus {
	background-color: #9e0b0f !important;
}

footer {
	font-family: "Montserrat", sans-serif;
}

.container {
	/* margin: 0 auto;
	max-width: 1200px;
	width: 95%; */
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	padding: 0 2.25%;
}
.dropLocation {
	position: absolute;
	right: 25.25%;
	top: 100px;
}

.container h1 {
	font-weight: 600;
	font-size: 2.5rem;
	padding: 40px 0 10px;
}

.container h2 {
	font-size: 2rem;
}

.container h3 {
	font-size: 1.4rem;
	margin: 10px 0;
}

.container h4 {
	font-weight: bold;
	font-size: 2rem;
}

b,
strong {
	font-weight: 500;
}

a {
	color: #9e0b0f;
	transition: all 0.2s ease-out;
}

a:hover {
	color: #85d80a;
}

.content800 {
	max-width: 100%;
	margin: 0 auto;
}

.container p {
	font-size: 20px;
	font-weight: 300;
}

nav ul a {
	font-family: "Roboto", sans-serif;
	font-size: 1rem;
	padding: 0;
	margin: 0 15px;
	line-height: 25px;
	color: #404040;
	text-transform: uppercase;
	font-weight: 300;
}

nav ul a:hover {
	background-color: transparent;
}

.container footer li {
	display: inline-block;
	margin: 5px 20px;
}

.container footer li a {
	color: #fff;
	text-transform: uppercase;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

.container footer li a:hover {
	color: black;
}

::placeholder {
	color: #000;
}

.headBg {
	background-image: url("/assets/images/P4-Hero.jpg");
	min-height: 320px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
}

nav,
nav .nav-wrapper i,
nav a.sidenav-trigger,
nav a.sidenav-trigger i {
	display: inline;
	float: right;
	height: 45px;
	line-height: 42px;
	font-size: 45px;
	border-radius: 5px;
}

nav .sidenav-trigger {
	margin: 30px 10px 0px;
}

.sidenav li > a {
	font-size: 17px;
	color: #9e0b0f;
	text-align: center;
	/* border-bottom: 1px solid; */
}

.sidenav li > a:hover {
	background-color: transparent;
	color: #85d80a;
}

::placeholder {
	color: #848484;
}

select {
	background-color: rgb(255 255 255);
	width: 100%;
	padding: 5px;
	border: 1px solid #f2f2f2;
	border-radius: 2px;
	height: 2.7rem;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea,
textarea.materialize-textarea {
	background-color: white;
	border: none;
	border-bottom: 1px solid #9e9e9e;
	border-radius: 2px;
	outline: none;
	height: 3.7rem;
	width: 92%;
	font-size: 18px;
	margin: 0 0 20px 0;
	padding: 0 4%;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
	transition: border 0.3s, -webkit-box-shadow 0.3s;
	transition: box-shadow 0.3s, border 0.3s;
	transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
	border-bottom: 1px solid #000;
	-webkit-box-shadow: 0 1px 0 0 #000;
	box-shadow: 0 1px 0 0 #000;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
	+ label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea:focus:not([readonly]) + label {
	color: #000;
}

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type="text"]:not(.browser-default),
input.valid[type="text"]:not(.browser-default):focus,
input.valid[type="password"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default):focus,
input.valid[type="email"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default):focus,
input.valid[type="url"]:not(.browser-default),
input.valid[type="url"]:not(.browser-default):focus,
input.valid[type="time"]:not(.browser-default),
input.valid[type="time"]:not(.browser-default):focus,
input.valid[type="date"]:not(.browser-default),
input.valid[type="date"]:not(.browser-default):focus,
input.valid[type="datetime"]:not(.browser-default),
input.valid[type="datetime"]:not(.browser-default):focus,
input.valid[type="datetime-local"]:not(.browser-default),
input.valid[type="datetime-local"]:not(.browser-default):focus,
input.valid[type="tel"]:not(.browser-default),
input.valid[type="tel"]:not(.browser-default):focus,
input.valid[type="number"]:not(.browser-default),
input.valid[type="number"]:not(.browser-default):focus,
input.valid[type="search"]:not(.browser-default),
input.valid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
	border-bottom: 1px solid #000;
	-webkit-box-shadow: 0 1px 0 0 #000;
	box-shadow: 0 1px 0 0 #000;
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
	border: 2px solid black;
}

[type="checkbox"]:checked + span:not(.lever):before {
	border-right: 2px solid black;
	border-bottom: 2px solid black;
}

.input-field .helper-text {
	position: absolute;
	min-height: 18px;
	display: block;
	font-size: 12px;
	bottom: 0px;
	color: #9e0b0f;
}

.clip-circle {
	clip-path: circle(50%);
}

.petCirle {
	border-radius: 50%;
	overflow: hidden;
}

.heart {
	cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/heart.png"),
		auto;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

.petCircleItem {
	margin: 30px 0;
}

.ssIcon a {
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	color: #282828;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	line-height: 44px;
	border-radius: 50%;
	font-size: 25px;
	margin: 10px;
	text-align: center;
	border: 1px solid;
	display: inline-block;
}

.fbIcon:hover {
	background-color: #3a5794;
	border: 1px solid #3a5794;
	color: white;
}

.twIcon:hover {
	background-color: #1c9cea;
	border: 1px solid #1c9cea;
	color: white;
}

.igIcon:hover {
	background-color: #da2f69;
	border: 1px solid #da2f69;
	color: white;
}

.eIcon:hover {
	background-color: #85d80a;
	border: 1px solid #85d80a;
	color: white;
}

/*---------------*/
/***** Lily *****/
/*---------------*/

/* Common style */
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	/* min-width: 320px;
	max-width: 480px;
	max-height: 360px; */
	width: 100%;
	/* background: #3085a3; */
	text-align: center;
	cursor: pointer;
	border-radius: 20px;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	/* word-spacing: -0.15em; */
	font-weight: 400;
	font-size: 1.4rem;
	font-family: "Montserrat", sans-serif;
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	font-size: 16px;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
}

figure.effect-lily img {
	max-width: none;
	width: -webkit-calc(100% + 50px);
	width: calc(100% + 50px);
	opacity: 1;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-40px, 0, 0);
	transform: translate3d(-40px, 0, 0);
}

figure.effect-lily figcaption {
	text-align: left;
}

figure.effect-lily figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px;
	width: 100%;
	height: 40%;
	background-color: rgba(0, 0, 0, 0.5);
}

figure.effect-lily h2,
figure.effect-lily p {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}

figure.effect-lily h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-lily p {
	color: rgba(255, 255, 255, 1);
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
	opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

figure.effect-lily:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}

/*---------------*/
/***** End Lily *****/
/*---------------*/

hr.fadeEnd {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0)
	);
}

.valignAdj {
	display: block;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.noBgCollapsable {
	box-shadow: none;
	border: 0;
	font-size: 17px;
	color: #444;
}

.noBgCollapsable li li:first-child {
	padding-top: 0;
}

.noBgCollapsable li li li {
	list-style-type: disc;
	margin-left: 30px;
	padding-left: 0;
}

.noBgCollapsable li li {
	border: 0;
	padding: 10px 0 0 40px;
}

.noBgCollapsable li {
	border-bottom: 1px solid #ddd;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.noBgCollapsable li:last-child {
	border-bottom: 0px;
}

.noBgCollapsableHead {
	display: block;
	border: 0;
	font-weight: bold;
}

.noBgCollapsableHead:hover {
	background-color: rgb(242, 242, 242);
}

.noBgCollapsableBody {
	border: 0;
	padding: 10px 15px 25px 15px;
}

.discStyle li {
	list-style-type: disc !important;
	padding-left: 5px !important;
	margin-left: 45px;
}

.numStyle {
	padding: 0px !important;
}

.numStyle li {
	padding-left: 5px !important;
	margin-left: 45px;
}

.hamburger {
	float: right;
	padding-top: 50px !important;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	background-color: #fff !important;
}

.p4Red-text {
	color: #9e0b0f;
}
.alignAdj {
	text-align: center;
}

/* Tablet style m-size */
@media only screen and (min-width: 601px) {
	.content800 {
		max-width: 800px;
	}

	.headBg {
		min-height: 420px;
	}
	.dropLocation {
		right: 16.25%;
	}
	.alignAdj {
		text-align: left;
	}
}

/* Desktop style l-size*/
@media only screen and (min-width: 993px) {
	.content800 {
		max-width: 800px;
	}

	.headBg {
		min-height: 530px;
	}

	.container h1 {
		font-size: 3.5rem;
	}

	.container {
		padding: 0 6.25%;
	}
	.dropLocation {
		right: 9.25%;
	}

	figure.effect-lily figcaption > div {
		height: 25%;
	}

	.valignAdj {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
	.show-on-medium-and-down {
		display: none !important;
	}
	.alignAdj {
		text-align: left;
	}
}

/* Desktop style xl-size*/
@media only screen and (min-width: 1900px) {
	.content800 {
		max-width: 800px;
	}

	.headBg {
		min-height: 700px;
	}
	.dropLocation {
		right: 6.25%;
	}
	.show-on-medium-and-down {
		display: none !important;
	}
	.alignAdj {
		text-align: left;
	}
}

button:focus {
	outline: none;
	background-color: black;
}

.flex {
	display: flex;
}

.flex.c-2 div {
	flex: 50%;
}

.partners .container div p:first-of-type {
	margin: 4px 0;
	/* margin-bottom: 0; */
}

.partners .container div p:last-of-type {
	margin: 4px 0;
	/* margin-top: 0; */
}

.partners .container p {
	font-size: 16px;
}
